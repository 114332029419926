.container {
    max-width: 1330px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
  }

.footer{
background-color: #000;
padding: 68px 0 90px;
.container{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.left-side{
  .top{
    display: flex;
    align-items: center;
    gap: 40px;
    span{
      display: block;
      border-left: 1px solid #fff;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;
      padding: 10px 0 10px 27px;
    }
  }
  .middle{
    margin-top: 38px;
    span{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;
      display: block;
      margin-bottom: 23px;
    }
    ul{
      display: flex;
      align-items: center;
      gap: 23px;
    }
  }
  img{
    margin-top: 52px;
    max-width: 800px;
  }
  .sponsor{
    margin-top: 26px;
  }
}
.right-side{
  width: 440px;
  span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    display: block;
    margin-bottom: 27px;
    
  }
  form{
    input{
      width: 100%;
      padding: 15px;
      margin-bottom: 27px;
      display: block;
      background: #111111;
      border: 1px solid #828282;
      border-radius: 15px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
      outline: none;
    }
    button{
      border: none;
      padding: 15px 0;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #000000;
      width: 100%;
      background: #F2F2F2;
      border-radius: 15px;
    }
  }
}
}

@media screen and (max-width: 600px) {
.footer{
background-color: #000;
padding: 38px 0;
.container{
flex-direction: column;
}
.left-side{
.top{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
.middle{
  margin-top: 38px;
  span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    display: block;
    margin-bottom: 23px;
  }
  ul{
    display: flex;
    align-items: center;
    gap: 23px;
  }
}
img{
  margin-top: 52px;
  width: 100%;
}
.sponsor{
  margin-top: 26px;
}
}
.right-side{
width: 100%;
margin-top: 30px;
}
}
}