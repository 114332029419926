html {
    scroll-behavior: smooth;
    overflow-x: hidden;
  }
  
  .ant-modal-content{
    padding: 0 !important;
  }
  
  .container {
    max-width: 1330px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
  }
  
  body {
    background: #F2F2F2;
    overflow-x: hidden;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    font-family: 'montserrat';
  }
  
  .univ-btn {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-align: right;
    color: #FFFFFF;
    padding: 11px 39px;
    background: #009AE2;
    box-shadow: 0px 0px 60px rgba(0, 154, 226, 0.55);
    border-radius: 31.5px;
    border: none;
    display: inline-block;
  }
  
  input{
    text-align: left;
  }
  
  .tag {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
  
  
    color: #FFFFFF;
  }
  
  .univ-text1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 98px;
    background: linear-gradient(90.73deg, #00AEFF 29.83%, #A2E2FF 72.43%, #46C6FD 99.74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  
  .univ-text2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 98px;
    /* identical to box height */
  
  
    /* Lingvela tugma */
  
    background: linear-gradient(180deg, #168ADE 0%, #00B2FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  
  .App {
    .banner {
      padding-bottom: 50px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
  
      .head {
        display: flex;
        align-items: center;
        gap: 93px;
        padding-top: 120px;
        padding-bottom: 30px;
  
        .links {
          ul {
            display: flex;
            align-items: center;
            gap: 93px;
  
            li {
              a {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;
              }
            }
          }
        }
      }
  
      .banner-main-text {
        padding: 73px 161px 53px 75px;
        max-width: 1100px;
        background: linear-gradient(135.98deg, rgba(0, 0, 0, 0.49) 2.93%, rgba(0, 0, 0, 0.17) 98.04%);
        backdrop-filter: blur(50px);
        border-radius: 64px;
  
        h1 {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 96px;
          line-height: 117px;
          background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(268.92deg, #00B2FF 2.9%, #F32C89 99.62%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          margin-bottom: 7px;
          color: #fff;
        }
  
        p {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 40px;
          color: #FFFFFF;
          margin-bottom: 33px;
          max-width: 863px;
        }
  
        .tag {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 30px;
          line-height: 37px;
          color: #FFFFFF;
          display: block;
          padding: 11px 41px;
          background: rgba(255, 255, 255, 0.15);
          border-radius: 29.5px;
          margin-bottom: 53px;
        }
      }
  
      .bottom-tag {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        display: block;
        padding: 61px 0 67px 50px;
      }
    }
  
    .linear-text {
      #rssBlock {
        left: 0px;
        background: #FFFFFF;
        position: absolute;
        width: 100%;
        overflow: hidden;
      }
  
      .cnnContents {
        width: 100%;
        margin: 0 auto;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        white-space: nowrap;
        color: #000000;
      }
  
      .marqueeStyle {
        display: inline-block;
        -webkit-animation: scrolling-left1 20s linear infinite;
        animation: scrolling-left1 40s linear infinite;
      }
  
      .marqueeStyle2 {
        display: inline-block;
        -webkit-animation: scrolling-left2 20s linear infinite;
        animation: scrolling-left2 40s linear infinite;
        animation-delay: 10s;
      }
  
      @keyframes scrolling-left1 {
        0% {
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
        }
  
        100% {
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
        }
      }
  
      @keyframes scrolling-left2 {
        0% {
          transform: translateX(0%);
          -webkit-transform: translateX(0%);
        }
  
        100% {
          transform: translateX(-200%);
          -webkit-transform: translateX(-200%);
        }
      }
  
      @-webkit-keyframes scrolling-left1 {
        0% {
          -webkit-transform: translateX(100%);
        }
  
        100% {
          -webkit-transform: translateX(-100%);
        }
      }
  
      @-webkit-keyframes scrolling-left2 {
        0% {
          -webkit-transform: translateX(0%);
        }
  
        100% {
          -webkit-transform: translateX(-200%);
        }
      }
  
    }
  
    .about-course {
      padding: 150px 0 105px 0;
  
      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
  
      .left-side {
        h1 {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 80px;
          line-height: 98px;
          background: linear-gradient(91.24deg, #863FFF 2.83%, #F32C89 99.58%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
  
        .tag {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          color: #000000;
        }
  
        p {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 25px;
          line-height: 30px;
          color: #000000;
          margin: 53px 0 64px 0;
          max-width: 694px;
        }
      }
  
      .right-side {
        text-align: center;
  
        iframe {
          width: 543px;
          height: 305px;
          border-radius: 25px;
        }
  
        h2 {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 25px;
          line-height: 30px;
          text-align: center;
          background: linear-gradient(90.75deg, #863FFF 9.83%, #F32C89 93.47%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          margin-top: 26px;
        }
  
        span {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          color: #000000;
        }
      }
    }
  
    .who-is-teachers {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 153px 0 83px 0;
      background-color: #000;
  
      .head {
        margin-bottom: 96px;
  
        p {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          color: #FFFFFF;
          margin-top: 36px;
          max-width: 836px;
        }
      }

      .teachers-card-wrapper{
        .head{
          h1{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 35px;
            line-height: 43px;
            color: #FFFFFF;
            text-align: center;
          }
        }
        .cards-wrapper{
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .card{
            text-align: center;
            img{
              width: 82.26px;
              height: 92px;
            }
            h3{
              margin: 26px 0 13px 0;
              max-width: 388px;
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 700;
              font-size: 35px;
              line-height: 43px;
              text-align: center;
              color: #FFFFFF;
            }
            p{
              max-width: 400px;
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 400;
              font-size: 22px;
              line-height: 27px;
              text-align: center;
              color: #FFFFFF;
            }
          }
        }
      }
  
      .slider {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 125px;
  
        img {
          width: 281px;
          height: 279px;
        }
  
        .text {
          h2 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 35px;
            line-height: 43px;
            color: #FFFFFF;
            margin-bottom: 5px;
          }
  
          span {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 27px;
            color: #FFFFFF;
          }
  
          p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            max-width: 419px;
            line-height: 22px;
            color: #FFFFFF;
            margin-top: 22px;
          }
        }
      }
  
      .button {
        display: flex;
        justify-content: flex-end;
        margin-top: 125px;
      }
    }
  
    .who-need-course {
      padding: 153px 0 73px 0;
  
      span {
        color: #000;
      }
  
      .features {
        margin-top: 73px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
  
        .features-item {
          width: 45%;
          display: flex;
          align-items: center;
          margin-bottom: 32px;
          gap: 60px;
  
          span {
            min-width: 70px;
            height: 70px;
            display: block;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(137.99deg, #E7E7E7 14.67%, rgba(248, 251, 255, 0) 95.94%);
            filter: drop-shadow(10px 10px 70px rgba(59, 64, 86, 0.1));
          }
  
          p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #000000;
            opacity: 0.8;
          }
        }
      }
  
      .button {
        display: flex;
        justify-content: flex-end;
        margin-top: 100px;
      }
    }
  
    .waiting-course {
      padding: 153px 0 110px 0;
      background-position: center;
      background-size: cover;
      background-color: #000;
  
      h1 {
        text-align: center;
      }
  
      .tag {
        text-align: center;
        display: block;
      }
  
      .features {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 80px;
        margin: 80px 0;
  
        .item {
          display: flex;
          align-items: center;
          gap: 33px;
          position: relative;
  
          &:nth-child(1) {
            &::before {
              top: 5px;
            }
          }
  
          &::before {
            content: '';
            top: 40px;
            left: -15px;
            width: 70px;
            height: 70px;
            position: absolute;
            background: linear-gradient(137.99deg, #000000 14.67%, #001B34 95.94%);
            box-shadow: 10px 16px 26px -9px #000000;
            border-radius: 50%;
          }
  
          span {
            display: block;
            position: relative;
          }
  
          p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 43px;
            color: #FFFFFF;
            max-width: 257px;
          }
        }
      }
  
      .square {
        border-radius: 41px;
        border: 1px solid #fff;
        padding: 80px 60px;
  
        .head {
          display: flex;
          align-items: center;
          gap: 16px;
          margin-bottom: 67px;
  
          h1 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 37px;
            color: #FFFFFF;
          }
        }
  
        .features-items {
  
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
  
          .item {
            width: 30%;
            margin-bottom: 112px;
            text-align: center;
  
            &:nth-child(4) {
              margin-bottom: 0;
            }
  
            &:nth-child(5) {
              margin-bottom: 0;
            }
  
            &:nth-child(6) {
              margin-bottom: 0;
            }
  
            h3 {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 700;
              font-size: 22px;
              line-height: 27px;
              color: #FFFFFF;
              margin-top: 25px;
            }
  
            span {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              text-align: center;
              color: #FFFFFF;
              display: block;
              margin-top: 4px;
            }
          }
        }
  
      }
  
      .button {
        display: flex;
        justify-content: center;
        margin-top: 108px;
      }
    }
  
    .online-course {
      padding: 153px 0 90px 0;
  
      .tag {
        color: #000;
      }
  
      .course-details {
        margin-top: 44px;
  
        ul {
          display: flex;
          align-items: center;
          gap: 10px;
  
          li {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: #000000;
  
            &:nth-child(2) {
              font-weight: 600;
            }
          }
        }
      }
  
      .course-fea {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 90px;
  
        .cards {
          width: 30%;
          text-align: center;
          margin-bottom: 117px;
          position: relative;
  
          &::before {
            position: absolute;
            content: '';
            background: linear-gradient(137.99deg, #E7E7E7 14.67%, rgba(248, 251, 255, 0) 95.94%);
            filter: drop-shadow(10px 10px 25px rgba(59, 64, 86, 0.3));
            border-radius: 50%;
            width: 70px;
            height: 70px;
            top: 10px;
            left: 38%;
          }
  
          span {
            position: relative;
          }
  
          h3 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #000000;
            margin-top: 36px;
          }
  
          p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: #000000;
            opacity: 0.8;
          }
        }
      }
  
      .conversation {
        margin-top: 161px;
  
        .head {
          margin-bottom: 107px;
        }
  
        h2 {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 40px;
          line-height: 49px;
  
          span {
            display: block;
            color: #168ADE;
          }
        }
  
        .conversation-human {
          display: flex;
          align-items: flex-end;
          margin-bottom: 43px;
  
          .note {
            padding: 33px;
            width: 443px;
            height: 417px;
            background: #FFFFFF;
            box-shadow: 0px 52px 41px 13px rgba(0, 0, 0, 0.05);
            border-radius: 11px;
  
            span {
              display: block;
  
              &:nth-child(1) {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #1B8CDE;
                margin-bottom: 22px;
              }
  
            }
  
            img {
              margin-top: 23px;
            }
          }
  
          &:nth-child(3) {
            display: flex;
            justify-content: flex-end;
            gap: 27px;
  
            .qiz {
              background: #FFFFFF;
              box-shadow: 0px 52px 41px 13px rgba(0, 0, 0, 0.05);
              border-radius: 11px;
              padding: 35px;
            }
  
            span {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 22px;
              color: #1B8CDE;
              display: block;
  
              &:nth-child(2) {
                color: #000;
                font-size: 15px;
                margin-top: 22px;
              }
            }
          }
  
          &:nth-child(4) {
            .bola {
              background: #FFFFFF;
              box-shadow: 0px 52px 41px 13px rgba(0, 0, 0, 0.05);
              border-radius: 11px;
              padding: 35px;
            }
  
            span {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 22px;
              color: #1B8CDE;
              display: block;
              &:nth-child(2){
                margin-top: 20px;
                color: #000;
                font-size: 16px;
              }
            }
          }
        }
      }
  
      .button {
        display: flex;
        justify-content: center;
        margin-top: 146px;
        margin-bottom: 90px;
      }
  
      .span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        display: block;
        text-align: center;
        padding: 9px 0;
        background: #D9D9D9;
        border-radius: 13px;
        max-width: 644px;
        margin: 0 auto;
      }
    }
  
    .achievment {
      padding: 153px 0 93px 0;
      background-position: center;
      background-size: cover;
      background-color: #000;
  
      .numbers {
        margin-top: 64px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
  
        .card {
          width: 30%;
          margin-bottom: 96px;
  
          h3 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            color: #FFFFFF;
            margin-top: 4px;
            margin-bottom: 10px;
          }
  
          span {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            width: 280px;
            color: #FFFFFF;
            display: block;
            opacity: 0.8;
          }
        }
      }
  
      .student-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 90px;
        padding: 0 100px;
  
        .card {
          width: 484px;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          border: 1px solid #fff;
          margin-bottom: 43px;
          padding: 30px;
  
          .head {
            display: flex;
            align-items: center;
            gap: 13px;
            margin-bottom: 21px;
  
            img {
              width: 65px;
              height: 65px;
              display: block;
              border-radius: 50%;
            }
  
            .name {
              span {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                display: block;
                line-height: 22px;
                color: #FFFFFF;
  
                &:nth-child(2) {
                  font-size: 15px;
                  font-weight: 400;
                }
              }
            }
          }
  
          p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #FFFFFF;
          }
        }
      }
  
      .button {
        display: flex;
        justify-content: center;
        margin-top: 76px;
      }
    }
  
    .how-enter {
      padding: 153px 0 107px 0;
  
      .tag {
        color: #000;
      }
  
      .steps {
        margin: 51px 0 164px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
  
        h1 {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 35px;
          line-height: 43px;
          color: #000000;
        }
  
        .step {
          margin-top: 64px;
          display: flex;
          align-items: flex-start;
          gap: 200px;
  
          .step-numb {
            text-align: center;
  
            span {
              display: block;
              max-width: 209px;
              margin-top: 13px;
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 32px;
              text-align: center;
              color: #000000;
            }
          }
        }
      }
  
      .plan {
        .head {
          text-align: center;
          margin-bottom: 110px;
  
          h2 {
            text-align: center;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 49px;
            color: #000000;
          }
  
          h3 {
            text-align: center;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 49px;
            color: #000000;
          }
        }
  
        .plans {
          padding-bottom: 282px;
          border-bottom: 1px solid #D9D9D9;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
  
          .plan-one,
          .plan-two,
          .plan-three {
            border-radius: 10px;
            .place {
              background: #2B2B2B;
              width: 100%;
              padding: 38px 0;
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: 0px 0px 33px rgba(242, 242, 242, 0.2);
              flex-direction: column;
  
              span {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                display: block;
                color: #FFFFFF;
                margin-bottom: 18px;
              }
            }
  
            width: 369px;
            padding-top: 42px;
            box-sizing: border-box;
            box-shadow: 0px 0px 27px 1px rgba(0, 0, 0, 0.05);
            border-radius: 11px;
  
            .head {
              margin-bottom: 30px;
  
              span {
                display: block;
              }
  
              .name {
                padding: 0 47px;
                display: block;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #000000;
              }
  
              .price {
                padding: 0 47px;
                display: block;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 45px;
                line-height: 55px;
                color: #000000;
                margin-top: 20px;
                display: flex;
                align-items: center;
                gap: 5px;
  
                span {
                  font-family: 'Montserrat';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 22px;
                  padding-top: 15px;
                  color: #6B6B6B;
                }
              }
  
              .real-price {
                padding: 0 47px;
                display: block;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #f4f4f4;
                width: 100%;
                border-bottom: 1px solid #D9D9D9;
                ;
                padding-bottom: 45px;
                text-decoration: line-through;
              }
  
            }
  
            ul {
              display: block;
              padding: 0 47px;
  
              li {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 23px;
  
                span {
                  font-family: 'Montserrat';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 15px;
                  line-height: 18px;
                  color: #000000;
                  opacity: 0.8;
                  text-align: left;
                }
  
                svg {
                  min-width: 16px;
                  height: 16px;
                }
              }
            }

            position: relative;
            
            &::before{
              position: absolute;
              width: 68px;
              height: 68px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 700;
              font-size: 22px;
              line-height: 27px;
              text-align: center;
              color: #FFFFFF;              
              top: -34px;
              z-index: 999;
              right: 58px;
              border-radius: 5px;
            }
          }
  
          .plan-one {
            background-color: #fff;
          }
  
          .plan-two {
            background: linear-gradient(170.89deg, #009AE2 0.63%, #9BDFFF 99.12%);
            &::before{
              content: '-10%';
              background-color: #2b2b2b;
            }
            .head {
              .price {
                color: #fff;
              }
            }
          }
  
          .plan-three {
            background: #2B2B2B;
            &::before{
              content: '-15%';
              background: linear-gradient(135.86deg, #009BE0 1.45%, #49C8FF 79.97%);
            }
            .head {
  
              .price,
              .name {
                color: #fff;
              }
            }
  
            ul {
              li {
                span {
                  color: #fff;
                }
              }
            }
          }
        }
      }
  
      .questions {
        margin-top: 168px;
  
        .ask {
          display: flex;
          align-items: center;
          gap: 20px;
          margin: 25px 0 55px 0;
  
          .quest {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 30px;
            text-align: right;
            color: #000000;
          }
        }
  
        ul {
          padding-bottom: 357px;
          border-bottom: 1px solid #168ADE;
  
          li {
            margin-bottom: 41px;
  
            span {
              display: block;
  
              &:nth-child(1) {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 22px;
                line-height: 27px;
                color: #000000;
                margin-bottom: 41px;
              }
  
              &:nth-child(2) {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                color: #A3A3A3;
                max-width: 600px;
              }
            }
          }
        }
      }
  
      .about {
        padding-top: 83px;
  
        h2 {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 50px;
          line-height: 61px;
          text-align: center;
          background: linear-gradient(180deg, #168ADE 0%, #00B2FF 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          margin-bottom: 59px;
        }
  
        p {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 25px;
          line-height: 30px;
          text-align: center;
          max-width: 1020px;
          color: #000000;
          margin: 0 auto;
        }
      }
    }
    
  }
  
  .form-one{
    max-width: 540px;
    background: #FFFFFF;
    border-radius: 15px;
    padding: 85px 56px 56px 56px;
    span{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #242424;
    }
    h1{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 61px;
      text-align: center;
      background: linear-gradient(180deg, #168ADE 0%, #00B2FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-top: 60px;
    }
    .modal-tag{
      text-align: center;
      display: block;
    }
    .radio{
      margin-top: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 50px;
      margin-bottom: 40px;
      .radio-one{
        text-align: center;
        label{
          display: block;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          text-align: center;
          color: #000000;
        }
        input{
          width: 24px;
          height: 24px;
        }
      }
      textarea{
        height: 204px;
        border: 1px solid #888888;
        border-radius: 34px;
        width: 100%;
      }
    }
    .inputs{
      margin-bottom: 48px;
      form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        input{
          display: block;
          width: 70%;
          padding: 23px;
          margin-bottom: 26px;
          outline: none;
          border: 1px solid #888888;
          border-radius: 34px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #888888;
        }
        button{
          padding: 23px;
          background: linear-gradient(180deg, #168ADE 0%, #00B2FF 100%);
          box-shadow: 0px 0px 60px rgba(0, 154, 226, 0.55);
          border-radius: 34px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          text-align: center;
          color: #FFFFFF;
          display: block;
          border: none;
          width: 70%;
        }
      }
    }
    .agree{
      display: flex;
      align-items: center;
      gap: 15px;
      label{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #888888;
      }
      input{
        width: 20px;
        height: 28px;
      }
    }
  }
  
  .result{
    padding: 90px 60px 60px 60px;
    h1{
      text-align: center;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      background: linear-gradient(180deg, #168ADE 0%, #00B2FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-bottom: 11px;
  
    }
    p{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      text-align: center;
      margin: 0 auto;
      max-width: 243px;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #000000;
      &.two{
        padding-bottom: 30px;
      }
      &.three{
        padding-bottom: 18px;
      }
    }
    .color{
      width: 100%;
      background-color: #00B2FF;
      height: 1px;
      margin: 20px 0 30px 0;
    }
    .social{
      padding: 26px 45px;
      box-sizing: border-box;
      border-radius: 13px;
      border: 1px solid #009AE2;
      ul{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 23px;
      }
    }
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  @media screen and (max-width: 600px) {
    .univ-btn {
      font-size: 18px;
      line-height: 21px;
      padding: 14px 20px;
    }
    .tag {
      font-size: 14px;
      line-height: 26px;
    }
    
    .univ-text1 {
      font-size: 38px;
      line-height: 46px;
    }
    
    .univ-text2 {
      font-size: 38px;
      line-height: 46px;
    }
    
    .App {
      .banner {
        padding-bottom: 50px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    
        .head {
          display: flex;
          align-items: center;
          gap: 93px;
          padding-top: 34px;
          padding-bottom: 30px;
    
          .links {
            display: none;
            ul {
              display: flex;
              align-items: center;
              gap: 93px;
    
              li {
                a {
                  font-family: 'Montserrat';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 20px;
                  color: #FFFFFF;
                }
              }
            }
          }
        }
    
        .banner-main-text {
          padding: 58px 23px 33px 23px;
          border-radius: 23px;
          h1 {
            font-size: 32px;
            line-height: 39px;
            margin-bottom: 20px;
          }
    
          p {
            font-size: 18px;
            line-height: 28px;
          }
    
          .tag {
            font-size: 16px;
            line-height: 18px;
            text-align: center;
          }
          .univ-btn{
            width: 176px;
            height: 51px;
            margin: 0 auto;
            display: block;
          }
        }
    
        .bottom-tag {
          font-size: 14px;
          line-height: 26px;
          padding: 61px 0 67px 0;
          max-width: 253px;
        }
      }
    
      .about-course {
        padding: 70px 0;
    
        .container {
          flex-direction: column;
        }
    
        .left-side {
          h1 {
            font-size: 38px;
            line-height: 46px;
            white-space: nowrap;
            margin-bottom: 11px;
          }
    
          .tag {
            font-size: 14px;
            line-height: 17px;
          }
    
          p {
            font-size: 16px;
            line-height: 28px;
            margin: 30px 0 ;
          }
        }
    
        .right-side {
          margin-top: 40px;
          width: 100%;
          iframe {
            width: 100%;
            height: 205px;
            border-radius: 25px;
          }
        }
      }
    
      .who-is-teachers {
        padding: 53px 0 53px 0;
        .head {
          margin-bottom: 46px;
          p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: #FFFFFF;
            margin-top: 36px;
            max-width: 836px;
          }
        }

        .teachers-card-wrapper{
          .head{
            margin-bottom: 100px;
            h1{
              font-size: 30px;
              line-height: 35px;
            }
          }
          .cards-wrapper{
            flex-direction: column;
            .card{
              margin-bottom: 50px;
              h3{
                margin: 0 0 10px 0;
                font-size: 28px;
                line-height: 35px;
              }
              p{
                font-size: 18px;
                line-height: 20px;
              }
            }
          }
        }

        
    
        .slider {
          gap: 0;
          flex-direction: column;
          img {
            width: 201px;
            height: 201px;
            margin-bottom: 30px;
          }
          .text{
            h2{
              font-size: 24px;
            }
            span{
              font-size: 15px;
            }
            p{
              font-size: 14px;
            }
          }
        }
    
        .button {
          display: flex;
          justify-content: center;
          margin-top: 40px;
        }
      }
    
      .who-need-course {
        padding: 53px 0;
        .features {
          margin-top: 33px;
          flex-direction: column;
          .features-item {
            width: 100%;
            margin-bottom: 20px;
            gap: 35px;
            span {
              width: 50px;
              height: 50px;
              background: transparent;
              svg{
                width: 50%;
                height: 50%;
              }
            }
    
            p {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
    
        .button {
          justify-content: center;
          margin-top: 40px;
        }
      }
    
      .waiting-course {
        padding: 53px 0 ;
        h1 {
          text-align: left;
        }
        .tag {
          text-align: left;
        }
    
        .features {
          flex-direction: column;
          padding: 0;
          margin: 50px 0 90px 0;
          .item {
            width: 100%;
            margin-bottom: 43px;
            &:nth-child(1) {
              &::before {
                top: -10px;
              }
            }
    
            &::before {
              top: 10px;
              left: -10px;
              width: 60px;
              height: 60px;
            }
    
            span {
              svg{
                height: 33px;
                width: 57px;
              }
            }
    
            p {
              font-size: 15px;
              line-height: 25px;
            }
          }
        }
    
        .square {
          padding: 44px 20px;
    
          .head {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 67px;
    
            h1 {
              font-size: 22px;
              line-height: 27px;
            }
          }
    
          .features-items {
            .item {
              width: 48%;
              margin-bottom: 61px;
              text-align: left;
              svg{
                width: 70px;
                height: 40px;
              }
    
              &:nth-child(4) {
                margin-bottom: 0;
              }
    
              &:nth-child(5) {
                margin-bottom: 0;
              }
    
              &:nth-child(6) {
                margin-bottom: 0;
              }
    
              h3 {
                font-size: 12px;
                line-height: 17px;
                margin-top: 12px;
                text-align: left;
              }
    
              span {
                font-size: 12px;
                line-height: 18px;
                text-align: left;
              }
            }
          }
    
        }
    
        .button {
          display: flex;
          justify-content: center;
          margin-top: 52px;
        }
      }
    
      .online-course {
        padding: 53px 0;
    
        .tag {
          color: #000;
        }
    
        .course-details {
          margin-top: 28px;
    
          ul {
            align-items: flex-start;
            li {
              font-size: 14px;
              line-height: 28px;
              &:nth-child(1){
                white-space: nowrap
              }
            }
          }
        }
    
        .course-fea {
          margin-top: 50px;
    
          .cards {
            width: 48%;
            margin-bottom: 40px;
            text-align: left;
            &::before{
              display: none;
            }
            svg{
              width: 56px;
              height: 46px;
            }
            h3 {
              font-size: 18px;
              text-align: left;
              line-height: 22px;
              margin-top: 16px;
              margin-bottom: 10px;
            }
    
            p {
              font-size: 14px;
              line-height: 18px;
              text-align: left;
            }
          }
        }
    
        .conversation {
          margin-top: 161px;
    
          .head {
            margin-bottom: 107px;
          }
    
          h2 {
            font-size: 30px;
            line-height: 40px;
          }
    
          .conversation-human {
            img{
              width: 80px;
              height: 80px;
            }
            .note {
              padding: 20px;
              width: 240px;
              height: auto;
              background: #FFFFFF;
              border-radius: 11px;
            }
    
            &:nth-child(3) {
              display: flex;
              justify-content: flex-end;
              gap: 27px;
    
              .qiz {
                padding: 20px;
              }
            }
    
            &:nth-child(4) {
              .bola {
                padding: 20px;
                span{
                  display: block;
                  &:nth-child(2)    {
                  font-size: 15px;
                  margin-top: 22px;
                  color: #000;

                  }
                }
              }
            }
          }
        }
    
        .button {
          display: flex;
          justify-content: center;
          margin: 70px 0;
        }
    
        .span {
          font-size: 16px;
          line-height: 20px;
        }
      }
    
      .achievment {
        padding: 53px 0;
        .numbers {
          .card {
            width: 45%;
            margin-bottom: 46px;
  
            svg{
              width: 50px;
              height: 50px;
            }
    
            h3 {
              font-size: 20px;
              margin-top: 10px;
              line-height: 22px;
            }
    
            span {
              font-size: 14px;
              line-height: 20px;
              max-width: 160px;
            }
          }
        }
    
        .student-cards {
          margin-top: 90px;
          padding: 0 ;
    
          .card {
            width: 100%;
          }
        }
      }
    
      .how-enter {
        padding: 53px 0 ;
        .steps {
          margin: 51px 0 64px;
          .step {
            margin-top: 64px;
            flex-direction: column;
            gap: 40px;
    
            .step-numb {
              width: 210px;
              span {
                font-size: 20px;
                line-height: 32px;
              }
            }
          }
        }
    
        .plan {
          .head {
            margin-bottom: 60px;
            h2 {
              text-align: left;
              font-size: 30px;
            }
    
            h3 {
              text-align: left;
              font-size: 20px;
              line-height: 39px;
            }
          }
    
          .plans {
            padding-bottom: 82px;
            flex-direction: column;
            .plan-one,
            .plan-two,
            .plan-three {
              width: 100%;
              margin-bottom: 50px;
              .head {
                margin-bottom: 30px;
                padding: 0 30px;
                .price{
                  padding: 0;
                }
                  }}
            }
        }
    
        .questions {
          margin-top: 68px;
          ul{
            padding-bottom: 40px;
          }
          .ask {
            gap: 0;
            .univ-btn{
              min-width: 170px;
            }
            .quest {
              text-align: left;
            }
          }
        }
    
        .about {
          padding-top: 53px;
          h2 {
            font-size: 30px;
            line-height: 41px;
            margin-bottom: 29px;
          }
    
          p {
            font-size: 20px;
            line-height: 25px;
          }
        }
      }
      
    }
    
    .form-one{
      max-width: 100%;
      padding: 40px 20px 20px 20px;
      span{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #242424;
      }
      h1{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 41px;
        text-align: center;
        background: linear-gradient(180deg, #168ADE 0%, #00B2FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-top: 40px;
        white-space: wrap !important;
      }
      .modal-tag{
        text-align: center;
        display: block;
      }
      .radio{
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 60px;
        margin-bottom: 20px;
        .radio-one{
          text-align: center;
          label{
            display: block;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            color: #000000;
          }
          input{
            width: 24px;
            height: 24px;
          }
        }
        textarea{
          height: 120px;
          border: 1px solid #888888;
          border-radius: 34px;
          width: 100%;
        }
      }
      .inputs{
        margin-bottom: 48px;
        form{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          input{
            display: block;
            width: 100%;
            padding: 15px;
            margin-bottom: 26px;
            outline: none;
            border: 1px solid #888888;
            border-radius: 34px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: #888888;
          }
          button{
            padding: 23px;
            background: linear-gradient(180deg, #168ADE 0%, #00B2FF 100%);
            box-shadow: 0px 0px 60px rgba(0, 154, 226, 0.55);
            border-radius: 34px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            color: #FFFFFF;
            display: block;
            border: none;
            width: 70%;
          }
        }
      }
      .agree{
        display: flex;
        align-items: center;
        gap: 15px;
        label{
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #888888;
        }
        input{
          width: 20px;
          height: 28px;
        }
      }
    }
    
    .result{
      padding: 30px;
      h1{
        text-align: center;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 49px;
        background: linear-gradient(180deg, #168ADE 0%, #00B2FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 11px;
    
      }
      p{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        text-align: center;
        margin: 0 auto;
        max-width: 343px;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: #000000;
        &.two{
          padding-bottom: 30px;
        }
        &.three{
          padding-bottom: 18px;
        }
      }
      .color{
        width: 100%;
        background-color: #00B2FF;
        height: 1px;
        margin: 20px 0 30px 0;
      }
      .social{
        padding: 26px 25px;
        box-sizing: border-box;
        border-radius: 13px;
        border: 1px solid #009AE2;
        ul{
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 23px;
        }
      }
    }
  }
  
  .swiper-button-prev{
    top: var(--swiper-navigation-top-offset,30%) !important;
  }
  .swiper-button-next{
    top: var(--swiper-navigation-top-offset,30%) !important;
  }